<template>
  <v-list-item @click.stop="generate" v-bind="$attrs">
    <ListIcon>
      <v-icon :color="color">{{ icon }}</v-icon>
    </ListIcon>
    <v-list-item-content>
      <v-list-item-title>
        {{ value.description }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-progress-circular
        :rotate="360"
        :size="30"
        :width="3"
        :value="progress"
        v-if="generating"
        color="grey"
      ></v-progress-circular>
      <v-icon v-else>mdi-download</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { reportIcon, reportColor } from "common/utils/icons.js";
import { getPeopleSort } from "common/utils/people.js";

export default {
  name: "ReportItem",
  props: {
    id: Number,
    value: { type: Object, default: () => {} },
  },
  data() {
    return {
      generating: false,
      progress: 0,
    };
  },
  computed: {
    color() {
      return reportColor(this.value);
    },
    icon() {
      return reportIcon(this.value);
    },
  },
  methods: {
    download(fileInfo) {
      this.generating = false;
      fileInfo.download();
    },
    error() {
      this.$root.showError("Fehler beim Erzeugen des Berichts.");
      this.generating = false;
    },
    async generate() {
      if (this.generating) return;
      this.progress = 0;
      this.generating = true;
      const parameters = JSON.parse(this.value.parameters);
      parameters.id = this.id;
      parameters.sort = getPeopleSort();
      const params = {
        resource: this.value.resource.endpoint,
        data: parameters,
        onProgress: this.updateProgress,
        onDownload: this.download,
        onError: this.error,
      };
      if (!(await this.apiReport(params))) {
        this.generating = false;
      }
    },
    updateProgress(progress) {
      this.progress = progress;
    },
  },
};
</script>
